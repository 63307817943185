
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import CustomerStories from "@Components/NEW_TS/Landing/HomeBeforeAfter";
import HomeHowItWorks from "@Components/NEW_TS/Landing/HomeHowItWorks";
import LandingSlider from "@Components/NEW_TS/Landing/LandingSlider";
import HomeTestimonials from "@Components/NEW_TS/Landing/LandingTestimonials";
import Layout from "@Containers/NEW_TS/Layout";
import { _GetClientReviews, _GetCustomerStories, _GetLandingSlider } from "@Services/Common";
import { useAuth } from "contexts/AuthContext/Auth.context";
import { configureAxiosLocale } from "lib/Axios";
import { NextPageContext } from "next";
import { generatePageSeo } from "next-seo.config";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import styled from "styled-components";
import { APIResponse, BaseMeta } from "types/ApiResponse";
import { ClientReview } from "types/common";
import { LandingSlide, LandingStory } from "types/landing";

const OrderReview = dynamic(() => import("@Components/NEW_TS/Landing/OrderReview"), {
  ssr: false
});

interface Props {
  sliderData?: LandingSlide[];
  // categoriesData?: CategoryData[];
  // productsCollectionData?: ShopCollection[];
  realStoriesData?: LandingStory[];
  // activeCollection?: ActiveCollection;
  homeTestmonials?: ClientReview[];
}

const LandingWrapper = styled.div`
  overflow: hidden;
`;

const Index = ({
  sliderData,
  // categoriesData,
  realStoriesData,
  // productsCollectionData,
  // activeCollection,
  homeTestmonials
}: Props) => {
  const { t } = useTranslation("common");
  const { locale, asPath } = useRouter();
  const { userToken } = useAuth();
  // const { data: lastDeliveredOrderData, revalidate } = useSWR(
  //   Boolean(userToken) ? [`/user/order/get-last-delivered-unreviewed`, userToken, locale] : null,
  //   {
  //     revalidateOnFocus: false,
  //     fetcher: () => _GetLatestUnReviewedDeliverdOrder(userToken, locale)
  //   }
  // );

  return (
    <Layout
      seoData={generatePageSeo(
        t("home-page-title"),
        locale,
        asPath,
        `${t("efreshli")} | %s `,
        t("about-section-summary")
      )}
    >
      <LandingWrapper>
        {/* {Boolean(lastDeliveredOrderData?.data?.order_number) && (
          <OrderReview orderData={lastDeliveredOrderData.data} revalidate={revalidate} />
        )} */}
        {Boolean(sliderData) && <LandingSlider data={sliderData} />}
        <HomeHowItWorks />
        {/* {Boolean(activeCollection) && <CuratedCollection data={activeCollection} />} */}
        {/* {Boolean(categoriesData) && <HomeRooms categoriesData={categoriesData} />} */}
        {/* {productsCollectionData?.map(collection => (
          <HomeBestSellers data={collection} />
        ))} */}
        {Boolean(realStoriesData) && <CustomerStories realStoriesData={realStoriesData} />}
        {Boolean(homeTestmonials) && <HomeTestimonials clientReviews={homeTestmonials} />}
        {/* <LandingFeatures /> */}
      </LandingWrapper>
    </Layout>
  );
};

 async function _getStaticProps(ctx: NextPageContext) {
  configureAxiosLocale(ctx.locale);
  let sliderData = {} as APIResponse<LandingSlide[], BaseMeta>;
  // let categoriesData = {} as APIResponse<CategoryData[], BaseMeta>;
  let realStoriesData = {} as APIResponse<LandingStory[], BaseMeta>;
  // let productsCollection = {} as APIResponse<ShopCollection[], Pagination>;
  // let activeCollection = {} as APIResponse<ActiveCollection, BaseMeta>;
  let homeTestmonials = {} as APIResponse<ClientReview[], BaseMeta>;

  // try {
  //   const { data: categoriesDataInit } = await listCategoriesWithSubs(ctx.locale);
  //   categoriesData = categoriesDataInit;
  // } catch (error) {
  //   console.log(error?.response);
  // }
  try {
    const { data: sliderDataInit } = await _GetLandingSlider(ctx.locale);
    sliderData = sliderDataInit;
  } catch (error) {
    console.log(error?.response);
  }
  try {
    const { data: realStoriesDataInit } = await _GetCustomerStories(ctx.locale);
    realStoriesData = realStoriesDataInit;
  } catch (error) {
    console.log(error?.response);
  }
  // try {
  //   const { data: activeCollectionsInit } = await _GetActiveCollections("active", ctx.locale);
  //   activeCollection = activeCollectionsInit;
  // } catch (error) {
  //   console.log(error?.response);
  // }

  // try {
  //   const { data: productsCollectionInit } = await _GetShopCollections(ctx.locale, {});
  //   productsCollection = productsCollectionInit;
  // } catch (error) {
  //   console.log(error?.response);
  // }

  try {
    const { data: homeTestmonialsInit } = await _GetClientReviews(ctx.locale);
    homeTestmonials = homeTestmonialsInit;
  } catch (error) {
    console.log(error?.response);
  }

  return {
    props: {
      // categoriesData: categoriesData?.data || null,
      realStoriesData: realStoriesData?.data || null,
      // productsCollectionData: productsCollection?.data || null,
      sliderData: sliderData?.data || null,
      // activeCollection: activeCollection?.data || null,
      homeTestmonials: homeTestmonials?.data || null
    },
    revalidate: 30
  };
}

export default Index;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  