import { Main } from "@Components/NEW_TS/common";
import Footer from "@Components/NEW_TS/Footer";
import Navbar from "@Components/NEW_TS/Navbar";
import ProfileNavigation from "@Components/NEW_TS/ProfileNavigation";
import { useAuth } from "contexts/AuthContext/Auth.context";
import dayjs from "dayjs";
import { DefaultSeoProps, NextSeo, ProductJsonLd } from "next-seo";
import Head from "next/head";
import { Fragment, ReactNode } from "react";
import { stripHtml } from "string-strip-html";
import { ProductData } from "types/Product";

interface Props {
  seoData: DefaultSeoProps;
  children: ReactNode;
  noFollow?: boolean;
  userProfileLayout?: boolean;
  product?: ProductData;
  hideFooter?: boolean;
}

export default function Layout({ seoData, noFollow, userProfileLayout, product, children, hideFooter }: Props) {
  const { userData } = useAuth();

  return (
    <Fragment>
      {product && (
        <Fragment>
          <Head>
            <meta property="product:price:currency" content="AED" />
            <meta property="og:price:currency" content="AED" />
            <meta property="product:availability" content="in stock" />
            <meta property="product:condition" content="new" />
            <meta property="product:price:amount" content={product?.variations[0]?.data?.price?.toString()} />
            <meta property="og:price:amount" content={product?.variations[0]?.data?.price?.toString()} />
            <meta property="product:retailer_item_id" content={product.sku} />
            <meta property="product:item_group_id" content={product.id?.toString()} />
            {Boolean(product?.google_merchant_category) && (
              <meta property="product:category" content={product.google_merchant_category} />
            )}
            {Boolean(product.brand?.name) && <meta property="product:brand" content={product.brand.name} />}
          </Head>
          <ProductJsonLd
            productName={product.title}
            images={[product.image_path, ...product.images.map(img => img.image_path)]}
            description={
              product.summary
                ? stripHtml(product.summary).result
                : product.description
                ? stripHtml(product.description).result
                : "Furnishing & decorating a space is exciting but knowing where to start and how to navigate can sometimes seem challenging. We created Efreshli to provide convenient furnishing & decoration services to help create a more accessible journey, all online. Whether you want to be fully involved or need a professional designer to take over, our process is about finding the right mix of products to bring your style to life."
            }
            brand={product?.brand?.name}
            offers={product?.variations?.map(variation => ({
              price: variation?.data?.price?.toString(),
              priceCurrency: "AED",
              priceValidUntil: dayjs(new Date(new Date().getFullYear(), 11, 31)).format("YYYY-MM-DD"),
              itemCondition: "https://schema.org/NewCondition",
              availability: "https://schema.org/InStock",
              url: `https://www.efreshli.ae/shop/${product.slug}`,
              seller: {
                name: product?.brand?.name || "Efreshli"
              }
            }))}
            sku={product.sku}
          />
        </Fragment>
      )}
      <NextSeo {...seoData} {...(noFollow && { nofollow: true, noindex: true })} />
      <Navbar />
      <Main>
        {Boolean(userProfileLayout) && Boolean(userData?.name) && <ProfileNavigation />}
        {children}
      </Main>
      {!hideFooter && <Footer />}
    </Fragment>
  );
}
