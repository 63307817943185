import Link from "@Components/NEW_TS/common/Link";
import { COOKIE_LANG } from "@Constants/index";
import useAnalytics from "@Hooks/useAnalytics";
import { analytics, firebase } from "@Services/Analytics";
import { useAuth } from "contexts/AuthContext/Auth.context";
import { configureAxiosLocale } from "lib/Axios";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { useCookie, useMedia } from "react-use";
import { Col, Row } from "styled-bootstrap-grid";
import { FluidContainer } from "../common/CustomGrid";
import {
  AboutEfreshli,
  AboutWrapper,
  Copyrights,
  FooterWrapper,
  LanguageSwitcher,
  Li,
  ListTitle,
  Logo,
  SocialMedia,
  StyledA,
  StyledLink,
  Ul
} from "./Footer.styled";
interface Props {}

export default function Footer({}: Props) {
  const { t } = useTranslation("common");
  const { logEvent } = useAnalytics();
  const isDesktop = useMedia("(min-width: 992px)");
  const { locales, locale, asPath, route } = useRouter();
  const invertedLocale = locales.find(l => l !== locale);
  const [cookieLang, updateCookieLang] = useCookie(COOKIE_LANG);
  const { userData, navCategories: categoriesData } = useAuth();

  const switchLangHandler = () => {
    configureAxiosLocale(invertedLocale);
    updateCookieLang(invertedLocale);
    logEvent("Language_Changed");
    if (firebase.apps.length) {
      analytics().setUserProperties({
        Language: invertedLocale === "ar" ? "ar-EG" : "en-US"
      });
    }
  };

  return (
    <Fragment>
      <FooterWrapper>
        <FluidContainer>
          <Row>
            <Col col={12} lg={4}>
              <AboutWrapper>
                <Link href="/">
                  <Logo src="/images/logo.svg" alt="Efreshli" />
                </Link>
                <AboutEfreshli>{t("about-section-summary")}</AboutEfreshli>
                {isDesktop && (
                  <Fragment>
                    <SocialMedia>
                      <StyledA target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/efreshli">
                        <FaFacebookF />
                      </StyledA>
                      <StyledA target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/efreshli/">
                        <FaInstagram />
                      </StyledA>
                      <StyledA
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://www.linkedin.com/company/efreshli"
                      >
                        <FaLinkedinIn />
                      </StyledA>
                      <StyledA
                        target="_blank"
                        rel="noreferrer noopener"
                        href="https://www.youtube.com/channel/UCLpm54zHBhBpH3qFlq9GzFg"
                      >
                        <FaYoutube />
                      </StyledA>
                    </SocialMedia>
                    {Boolean(route !== "/style/[id]") && (
                      <LanguageSwitcher>
                        <div>{t("language")}:</div>
                        <Link onClick={switchLangHandler} href={asPath} locale={locale === "ar" ? "en" : "ar"}>
                          <span>{locale === "ar" ? "English" : "Arabic"}</span>{" "}
                          <img
                            src={locale === "ar" ? "/images/english-flag.png" : "/images/emirates-flag.svg"}
                            alt=""
                          />
                        </Link>
                      </LanguageSwitcher>
                    )}
                  </Fragment>
                )}
              </AboutWrapper>
            </Col>
            <Col col={6} sm={4} lg={2} lgOffset={4}>
              <ListTitle>{t("profile")}</ListTitle>
              <Ul>
                <Li>
                  <StyledLink href={Boolean(userData) ? "/profile" : "/login"}>{t("my-account")}</StyledLink>
                </Li>
                <Li>
                  <StyledLink href="/blog">{t("blog")}</StyledLink>
                </Li>
                <Li>
                  <StyledLink href="/projects">{t("projects")}</StyledLink>
                </Li>
              </Ul>
            </Col>
            <Col col={6} sm={4} lg={2}>
              <ListTitle className="last-one">{t("customer-service")}</ListTitle>
              <Ul>
                <Li>
                  <StyledLink href="/contact">{t("help-contact")}</StyledLink>
                </Li>
                <Li>
                  <StyledLink href="/return-policy">{t("return-policy")}</StyledLink>
                </Li>
              </Ul>
            </Col>
            <Col col={12}>
              {!isDesktop && (
                <Fragment>
                  <SocialMedia>
                    <StyledA target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/efreshli">
                      <FaFacebookF />
                    </StyledA>
                    <StyledA target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/efreshli/">
                      <FaInstagram />
                    </StyledA>
                    <StyledA target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/company/efreshli">
                      <FaLinkedinIn />
                    </StyledA>
                    <StyledA
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.youtube.com/channel/UCLpm54zHBhBpH3qFlq9GzFg"
                    >
                      <FaYoutube />
                    </StyledA>
                  </SocialMedia>
                  {Boolean(route !== "/style/[id]") && (
                    <LanguageSwitcher>
                      <div>{t("language")}:</div>
                      <Link onClick={switchLangHandler} href={asPath} locale={locale === "ar" ? "en" : "ar"}>
                        <span>{locale === "ar" ? "English" : "Arabic"}</span>{" "}
                        <img src={locale === "ar" ? "/images/english-flag.png" : "/images/emirates-flag.svg"} alt="" />
                      </Link>
                    </LanguageSwitcher>
                  )}
                </Fragment>
              )}
            </Col>
          </Row>
        </FluidContainer>
      </FooterWrapper>
      <Copyrights>
        &copy;{new Date().getFullYear()} Efreshli, {t("rights-reserved")}
      </Copyrights>
    </Fragment>
  );
}
