import Link from "@Components/NEW_TS/common/Link";
import styled from "styled-components";

export const FooterWrapper = styled.footer`
  padding: 55px 0 45px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  background: #fff;
  position: relative;
`;
export const FooterInner = styled.div`
  display: flex;
`;
export const AboutWrapper = styled.div``;
export const Logo = styled.img`
  display: block;
  width: 112.7px;
  margin-bottom: 36px;
`;
export const AboutEfreshli = styled.p`
  font-size: 1.3rem;
  line-height: 2;
  color: #808080;
  margin-bottom: 30px;
`;
export const SocialMedia = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  @media (max-width: 991.98px) {
    margin-top: 40px;
    justify-content: center;
  }
`;
export const StyledA = styled.a`
  color: #c5c5c5;
  font-size: 2rem;
  line-height: 1;
`;
export const ListTitle = styled.h4`
  font-size: 1.6rem;
  white-space: nowrap;
  font-weight: 600;
  line-height: 1;
  color: #000;
  margin-bottom: 30px;
  &.last-one {
    @media (max-width: 575.98px) {
      margin-top: 60px;
    }
  }
`;
export const Ul = styled.ul``;
export const Li = styled.li`
  line-height: 1;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: 575.98px) {
    margin-bottom: 20px;
  }
`;
export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.textColor};
  white-space: nowrap;
  line-height: 1;
  transition: 0.3s ease color;
  font-size: 1.3rem;
  &:hover {
    color: ${({ theme }) => theme.colors.brandColor};
  }
  @media (max-width: 575.98px) {
    font-size: 1.4rem;
  }
`;
export const Copyrights = styled.p`
  color: #aeaeae;
  line-height: 1;
  padding: 24px 0;
  text-align: center;
  font-size: 1.4rem;
  text-transform: uppercase;
  background: #fff;
  position: relative;
`;
export const LanguageSwitcher = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #000;
  line-height: 1;
  div {
    margin-right: 7px;
  }
  a {
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
  }
  img {
    display: block;
    min-width: 21px;
    max-width: 21px;
    min-height: 21px;
    max-height: 21px;
    margin-left: 8px;
    margin-top: -4px;
  }
`;
