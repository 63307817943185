import { useAuth } from "contexts/AuthContext/Auth.context";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import React, { ReactElement } from "react";
import { Col, Row } from "styled-bootstrap-grid";
import { FluidContainer } from "../common/CustomGrid";
import Icon from "../common/Icon";
import {
  AccountLogout,
  AccountName,
  AccountWrapper,
  CharAvatar,
  Li,
  ProfileNav,
  ProfileNavInner,
  ProfileNavLink,
  ProfileNavWrapper,
  Ul
} from "./ProfileNavigation.styled";

export default function ProfileNavigation(): ReactElement {
  const { userData } = useAuth();
  const { asPath } = useRouter();
  const { t } = useTranslation("common");
  const { logOut } = useAuth();

  const logoutHandler = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    logOut();
  };

  return (
    <ProfileNavWrapper>
      <FluidContainer>
        <Row>
          <Col lg={2} md={3}>
            <ProfileNavInner>
              <AccountWrapper>
                <CharAvatar>{userData.name.slice(0, 1)}</CharAvatar>
                <div>
                  <AccountName>
                    {userData.name} {userData.last_name}
                  </AccountName>
                  <AccountLogout type="button" onClick={logoutHandler}>
                    {t("logout")}
                  </AccountLogout>
                </div>
              </AccountWrapper>
              <ProfileNav>
                <Ul>
                  <Li>
                    <ProfileNavLink href="/profile" isActive={asPath === "/profile"}>
                      <Icon icon="user-profile" />
                      {t("profile")}
                    </ProfileNavLink>
                  </Li>
                  <Li>
                    <ProfileNavLink href="/profile/orders" isActive={asPath.includes("/profile/orders")}>
                      <Icon icon="orders-profile" />
                      {t("orders")}
                    </ProfileNavLink>
                  </Li>
                  <Li>
                    <ProfileNavLink href="/profile/addresses" isActive={asPath === "/profile/addresses"}>
                      <Icon icon="locations" />
                      {t("addresses")}
                    </ProfileNavLink>
                  </Li>
                  <Li>
                    <ProfileNavLink
                      href="/profile/projects-transactions"
                      isActive={asPath.includes("/profile/projects-transactions")}
                    >
                      <Icon icon="projects-transactions" />
                      {t("projects-transactions")}
                    </ProfileNavLink>
                  </Li>
                </Ul>
              </ProfileNav>
            </ProfileNavInner>
          </Col>
        </Row>
      </FluidContainer>
    </ProfileNavWrapper>
  );
}
