import Icon from "@Components/common/Icon";
import { stripButton } from "@Utils/style-util";
import styled from "styled-components";
import { Swiper } from "swiper/react";

export const SectionWrapper = styled.section`
  position: relative;
  margin-bottom: 135px;
  @media (max-width: 767.98px) {
    margin-bottom: 50px;
  }
  .stories-pagination {
    margin-top: 15px;
    display: flex;
    align-items: center;
    line-height: 1;
    justify-content: center;
    .swiper-pagination-bullet {
      margin-right: 16px;
      min-width: 10px;
      max-width: 10px;
      min-height: 10px;
      max-height: 10px;
      border: 1px solid #fff;
      background-color: #000;
      opacity: 1;
      transition: 0.2s ease background-color;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .swiper-pagination-bullet-active {
      border: 1px solid #000;
      background-color: #fff;
      min-width: 11px;
      max-width: 11px;
      min-height: 11px;
      max-height: 11px;
    }
    @media (max-width: 575.98px) {
      display: none;
    }
  }
`;
export const SectionTitle = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  @media (min-width: 767.98px) {
    font-size: 3.2rem;
  }
`;
export const SectionTitleLabel = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1;
  color: #ed1c23;
  display: flex;
  align-items: center;
  &:before {
    content: "";
    height: 2px;
    width: 25px;
    background-color: #ed1c23;
    display: block;
    margin-right: 12px;
  }
`;
export const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  @media (min-width: 767.98px) {
    margin-bottom: 55px;
  }
`;
export const ImageWrapper = styled.div`
  margin: 0 auto;
  padding: 15px;
  border: solid 1px #d8d8d8;
  border-radius: 2px;
  position: relative;
  height: 547px;
  img {
    object-position: center;
  }
  .swiper-slide {
    height: 517px;
    & > div {
      height: 100% !important;
    }
  }
  @media (max-width: 575.98px) {
    height: 254px;
    padding: 10px;
    .swiper-slide {
      height: 234px;
    }
  }
`;
export const ImageCompareWrapper = styled.div`
  position: relative;
`;

export const DummyImg = styled.img`
  position: absolute;
  z-index: -1000;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
`;
export const Carousel = styled(Swiper)`
  height: 100%;
  .swiper-slide {
    direction: ltr /*rtl:ltr */;
  }
`;
export const CompareLabel = styled.div`
  position: absolute;
  /*rtl:ignore*/
  left: 20%;
  bottom: 0;
  width: 90px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 1;
  font-size: 1.3rem;
  color: #f06369;
  line-height: 1;
  font-weight: 500;
  &.after {
    /*rtl:ignore*/
    left: auto;
    /*rtl:ignore*/
    right: 20%;
    background-color: #000;
    color: #fff;
  }
  @media (max-width: 575.98px) {
    width: 42px;
    height: 15px;
    font-size: 1rem;
  }
`;
export const NavigationsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  @media (min-width: 575.98px) {
    display: none;
  }
`;
export const NextArrow = styled(Icon)`
  transition: 0.4s ease transform;
  line-height: 1;
  font-weight: bold;
  font-size: initial;
  height: fit-content;
  color: #000;
  margin-top: -2px;
  transition: 0.2s ease color;
  && {
    transform: rotate(0deg) /*rtl: rotate(180deg) */;
  }
`;

export const SliderBtn = styled.button`
  ${stripButton}
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  gap: 8px;
  display: flex;
  align-items: baseline;
  color: rgba(0, 0, 0, 0.87);
  transition: 0.2s ease color;
  &:hover {
    color: #e60000;
    ${NextArrow} {
      color: #e60000;
    }
  }
`;
export const Separator = styled.div`
  flex: 1;
  width: 100%;
  height: 1.5px;
  background-color: #333;
`;
export const LeftLeaf = styled.img`
  pointer-events: none;
  user-select: none;
  z-index: -1;
  position: absolute;
  width: 158.4px;
  height: 174.3px;
  left: 0;
  top: 30px;
  transform: scale(1, 1) /*rtl: scale(-1, 1)*/;
  @media (max-width: 767.98px) {
    display: none;
  }
`;
export const RightLeaf = styled.img`
  pointer-events: none;
  user-select: none;
  z-index: -1;
  position: absolute;
  width: 266.2px;
  height: 343px;
  top: 30%;
  right: 0;
  transform: scale(1, 1) /*rtl: scale(-1, 1)*/;
  @media (max-width: 767.98px) {
    display: none;
  }
`;
