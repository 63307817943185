import { stripButton } from "@Utils/style-util";
import styled, { css } from "styled-components";
import { Swiper } from "swiper/react";

export const SectionWrapper = styled.section`
  position: relative;
  margin-bottom: 56px;
  @media (min-width: 767.98px) {
    margin-bottom: 60px;
  }
`;
export const Carousel = styled(Swiper)`
  .swiper-slide {
    padding: 15px 0;
    height: auto;
  }
`;
export const SectionTitle = styled.h2`
  margin-bottom: 3.4rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  @media (min-width: 767.98px) {
    font-size: 3.2rem;
    margin-bottom: 6.5rem;
  }
`;
export const CommentWrapper = styled.div`
  background-color: #fff;
  border-radius: 2px;
  border: solid 1px #e7e7e7;
  padding: 43px 26px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1;
  position: relative;
  height: 100%;
  &:before {
    content: "";
    position: absolute;
    bottom: -15px;
    right: -15px;
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    z-index: -1;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -15px;
    right: -0;
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    z-index: -1;
  }
`;
export const Comment = styled.p`
  text-align: left;
  font-size: 1.3rem;
  line-height: 1.7;
  color: #777;
  margin-bottom: 29px;
  max-height: ${13 * 1.7 * 4}px;
  overflow: hidden;
`;
export const CommenterWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const CommenterImg = styled.img<{ readonly clientName?: string }>`
  min-width: 45px;
  max-width: 45px;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 50%;
  ${({ clientName }) =>
    clientName &&
    css`
      align-items: baseline;
      justify-content: center;
      border: 1px solid #dedede;
      background-color: #fafafa;
      &:after {
        content: "${clientName
          .split(" ")
          .splice(0, 2)
          .map(n => n[0])
          .join("")}";
        text-transform: uppercase;
        font-size: 1.4rem;
        color: #333;
        line-height: 45px;
      }
    `}
`;
export const CommentByWrapper = styled.h4`
  padding-top: 3px;
`;
export const CommenterName = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  margin-bottom: 7px;
  @media (min-width: 767.98px) {
    font-weight: 500;
  }
`;
export const CommenterBio = styled.div`
  font-size: 1.3rem;
  color: #777;
  font-weight: 400;
`;
export const QuotationWrapper = styled.div`
  display: flex;
  position: absolute;
  top: -15px;
  right: 15px;
`;
export const Quotation = styled.div`
  width: 16.3px;
  height: 31.3px;
  background-color: #f7f7f7;
  position: absolute;
  margin-right: 10px;
  position: relative;
  &:last-of-type {
    margin-right: 0;
  }
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 16.3px solid #fff;
    border-right: 16.3px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
export const CarouselWrapper = styled.div`
  position: relative;
  .testimonials-pagination {
    margin-top: 15px;
    display: flex;
    align-items: center;
    line-height: 1;
    justify-content: center;
    .swiper-pagination-bullet {
      margin-right: 16px;
      min-width: 10px;
      max-width: 10px;
      min-height: 10px;
      max-height: 10px;
      border: 1px solid #fff;
      background-color: #000;
      opacity: 1;
      transition: 0.2s ease background-color;
      &:last-of-type {
        margin-right: 0;
      }
      &-active {
        background-color: #000;
      }
    }
    .swiper-pagination-bullet-active {
      border: 1px solid #000;
      background-color: #fff;
      min-width: 11px;
      max-width: 11px;
      min-height: 11px;
      max-height: 11px;
    }
    @media (max-width: 575.98px) {
      display: none;
    }
  }
`;
export const NavigationsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  @media (min-width: 575.98px) {
    display: none;
  }
`;
export const SliderBtn = styled.button`
  ${stripButton}
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  &:hover {
    color: #e60000;
  }
`;
export const Separator = styled.div`
  flex: 1;
  width: 100%;
  height: 1.5px;
  background-color: #333;
`;
export const TopLeftLeaf = styled.img`
  position: absolute;
  left: 0;
  top: 0px;
  width: 148.8px;
  height: 134.9px;
  pointer-events: none;
  user-select: none;
  z-index: -1;
  transform: scale(-1.1, 1.1);
  @media (max-width: 767.98px) {
    display: none;
  }
`;
