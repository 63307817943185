import styled from "styled-components";
import Link from "../common/Link";

export const ProfileNavWrapper = styled.div`
  position: fixed;
  top: 81px;
  left: 0;
  width: 100%;
  bottom: 0;
  @media (max-width: 767.98px) {
    display: none;
  }
`;
export const ProfileNavInner = styled.div`
  height: calc(100vh - 117px);
  background-color: #fff;
  border-right: 1px solid rgba(112, 112, 112, 0.15);
  padding: 40px 20px 0 0;
  overflow-y: auto;
`;
export const AccountWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
  }
`;
export const CharAvatar = styled.span`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #000;
  border: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 2rem;
  user-select: none;
`;
export const AccountName = styled.div`
  font-size: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  line-height: 1;
  margin-bottom: 10px;
  width: 100%;
`;
export const AccountLogout = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0;
  line-height: 1.2 /* rtl:1.3 */;
  display: inline;
`;
export const ProfileNav = styled.nav``;
export const Ul = styled.ul``;
export const Li = styled.li`
  line-height: 1;
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
export const ProfileNavLink = styled(Link)<{ readonly isActive?: boolean }>`
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  color: ${({ isActive }) => (isActive ? "#f06369" : "#000000")};
  font-weight: ${({ isActive }) => (isActive ? "500" : "400")};
  i {
    margin-right: 15px;
    font-size: 2rem;
  }
`;
