import { rgba } from "@Utils/style-util";
import styled from "styled-components";

export const HowHeading = styled.h2`
  font-size: 3.5rem;
  font-weight: 600;
  text-align: center;
  color: #000;
  margin-bottom: 65px;
  line-height: 1;
  @media (max-width: 767.98px) {
    font-weight: 500;
    margin-bottom: 24px;
    font-size: 2rem;
  }
`;

export const HowWrapper = styled.div`
  display: flex;
  @media (max-width: 991.98px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const HowImageWrapper = styled.div`
  min-width: 563px;
  max-width: 563px;
  height: 424px;
  margin-right: 53px;
  border: solid 1px #ddd;
  padding: 15px;
  @media (max-width: 1199.98px) {
    min-width: 500px;
    max-width: 500px;
  }
  @media (max-width: 991.98px) {
    min-width: unset;
    max-width: unset;
    margin-right: 0;
    width: 100%;
    height: 320px;
    margin-bottom: 30px;
  }
  @media (max-width: 767.98px) {
    height: 252px;
  }
`;
export const HowImage = styled.img`
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
`;

export const Steps = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 991.98px) {
    padding-left: 35px;
  }
`;

export const HowStepWrapper = styled.div`
  position: relative;
  flex: 1;
  border-radius: 5px;
  border: solid 1px ${rgba("#f06369", 0.5)};
  padding: 20px 40px 0 43px;
  background-color: #fff;
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: 991.98px) {
    padding: 20px 40px;
  }
  @media (max-width: 767.98px) {
    padding: 20px 10px 20px 33px;
  }
`;

export const HowStepHeading = styled.h3`
  color: #000;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 12px;
  @media (max-width: 1199.98px) {
    margin-bottom: 8px;
  }
`;

export const HowStepText = styled.p`
  font-size: 1.4rem;
  line-height: 1.57;
  color: rgba(82, 82, 82, 0.87);
  @media (max-width: 1199.98px) {
    font-size: 1.3rem;
  }
`;

export const HowStepNumber = styled.h3`
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f06369;
  font-size: 9.6rem;
  font-weight: 700;
  color: #fff8f9;
  font-family: "Averta";
  position: absolute;
  left: -30px /* rtl: 96% */;
  top: -5px;
`;
export const HowItWorksWrapper = styled.section`
  margin-bottom: 100px;
  position: relative;
  @media (max-width: 575.98px) {
    margin-bottom: 60px;
  }
`;
export const HowLeaf = styled.img`
  z-index: -1;
  user-select: none;
  pointer-events: none;
  width: 262px;
  height: 369px;
  position: absolute;
  right: 0;
  bottom: 0;
  @media (max-width: 991.98px) {
    width: 250.8px;
    height: 369px;
    bottom: auto;
    top: -150px;
  }
`;
