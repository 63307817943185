import { HybridContainer } from "@Components/NEW_TS/common/CustomGrid";
import useTranslation from "next-translate/useTranslation";
import React from "react";
import { Col, Row } from "styled-bootstrap-grid";
import {
  HowHeading,
  HowImage,
  HowImageWrapper,
  HowItWorksWrapper,
  HowLeaf,
  HowWrapper,
  Steps
} from "./HomeHowItWorks.styled";
import HowStep from "./HowStep";

export default function HomeHowItWorks() {
  const { t } = useTranslation("common");
  return (
    <HowItWorksWrapper>
      <HybridContainer>
        <Row>
          <Col col={12}>
            <HowHeading>{t("how-it-works")}</HowHeading>
            <HowWrapper>
              <HowImageWrapper>
                <HowImage src="/images/landing-how.png" />
              </HowImageWrapper>
              <Steps>
                <HowStep stepCount={1} stepHeading={t("step-1-heading")} stepText={t("step-1-text")} />
                <HowStep stepCount={2} stepHeading={t("step-2-heading")} stepText={t("step-2-text")} />
                <HowStep stepCount={3} stepHeading={t("step-3-heading")} stepText={t("step-3-text")} />
              </Steps>
            </HowWrapper>
          </Col>
        </Row>
      </HybridContainer>
      <HowLeaf src="/images/how-leaf.svg" alt="" />
    </HowItWorksWrapper>
  );
}
