import { HybridContainer } from "@Components/NEW_TS/common/CustomGrid";
import useTranslation from "next-translate/useTranslation";
import { Col, Row } from "styled-bootstrap-grid";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import { ClientReview } from "types/common";
import { NextArrow, SliderBtn } from "../HomeBeforeAfter/CustomerStories.styled";
import {
  Carousel,
  CarouselWrapper,
  Comment,
  CommentByWrapper,
  CommenterImg,
  CommenterName,
  CommenterWrapper,
  CommentWrapper,
  NavigationsWrapper,
  Quotation,
  QuotationWrapper,
  SectionTitle,
  SectionWrapper,
  Separator,
  TopLeftLeaf
} from "./LandingTestimonials.styeld";

SwiperCore.use([Navigation, Pagination]);

interface Props {
  clientReviews: ClientReview[];
}

export default function HomeTestimonials({ clientReviews }: Props) {
  const { t } = useTranslation("common");
  return (
    <SectionWrapper>
      <HybridContainer>
        <Row justifyContent="center">
          <Col col={10}>
            <SectionTitle>{t("what-our-clients-say")}</SectionTitle>
            <CarouselWrapper>
              <Carousel
                spaceBetween={40}
                slidesPerView={1}
                pagination={{
                  el: ".testimonials-pagination",
                  type: "bullets",
                  clickable: true
                }}
                navigation={{
                  prevEl: ".testimonials-prev",
                  nextEl: ".testimonials-next"
                }}
                breakpoints={{
                  "576": {
                    slidesPerView: 2,
                    spaceBetween: 40
                  },
                  "992": {
                    slidesPerView: 2,
                    spaceBetween: 40
                  },
                  "1024": {
                    slidesPerView: 3,
                    spaceBetween: 40
                  }
                }}
              >
                {clientReviews.map(comment => (
                  <SwiperSlide key={comment.client_review_date}>
                    <CommentWrapper>
                      <QuotationWrapper>
                        <Quotation />
                        <Quotation />
                      </QuotationWrapper>
                      <Comment>{comment.client_message}</Comment>
                      <CommenterWrapper>
                        <CommenterImg
                          as={comment.client_image ? "img" : "div"}
                          src={comment.client_image}
                          alt=""
                          {...(comment.client_image
                            ? {}
                            : {
                                clientName: comment.client_name,
                                as: "div"
                              })}
                        />
                        <CommentByWrapper>
                          <CommenterName>{comment.client_name}</CommenterName>
                        </CommentByWrapper>
                      </CommenterWrapper>
                    </CommentWrapper>
                  </SwiperSlide>
                ))}
              </Carousel>
              <div className="testimonials-pagination" />
            </CarouselWrapper>
          </Col>
        </Row>
        <NavigationsWrapper>
          <SliderBtn className="testimonials-prev">
            <NextArrow icon="arrow-left" />
            {t("_prev").toLocaleUpperCase()}
          </SliderBtn>
          <Separator />
          <SliderBtn className="testimonials-next">
            {t("next").toLocaleUpperCase()}
            <NextArrow icon="arrow-right" />
          </SliderBtn>
        </NavigationsWrapper>
      </HybridContainer>
      <TopLeftLeaf src="images/best-sellers-top-right.svg" alt="" />
    </SectionWrapper>
  );
}
