import { HybridContainer } from "@Components/NEW_TS/common/CustomGrid";
import useTranslation from "next-translate/useTranslation";
import ReactCompareImage from "react-compare-image";
import { Col, Row } from "styled-bootstrap-grid";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import { LandingStory } from "types/landing";
import {
  Carousel,
  CompareLabel,
  ImageCompareWrapper,
  ImageWrapper,
  LeftLeaf,
  NavigationsWrapper,
  NextArrow,
  RightLeaf,
  SectionTitle,
  SectionTitleLabel,
  SectionTitleWrapper,
  SectionWrapper,
  Separator,
  SliderBtn
} from "./CustomerStories.styled";

SwiperCore.use([Navigation, Pagination]);
interface Props {
  realStoriesData: LandingStory[];
}

export default function CustomerStories({ realStoriesData }: Props) {
  const { t } = useTranslation("common");

  return (
    <SectionWrapper>
      <HybridContainer>
        <Row justifyContent="center">
          <Col col={12} md={10}>
            <SectionTitleWrapper>
              <SectionTitleLabel>
                {t("before")} | {t("after")}
              </SectionTitleLabel>
              <SectionTitle>{t("real-customer-stories")}</SectionTitle>
            </SectionTitleWrapper>
            <ImageWrapper>
              <ImageCompareWrapper>
                <Carousel
                  allowTouchMove={false}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{
                    el: ".stories-pagination",
                    type: "bullets",
                    clickable: true
                  }}
                  navigation={{
                    prevEl: ".stories-slider-prev",
                    nextEl: ".stories-slider-next"
                  }}
                >
                  {realStoriesData.map(obj => (
                    <SwiperSlide key={obj.id + "_Landing_Slide"}>
                      <ReactCompareImage
                        leftImage={obj.image_before_path}
                        rightImage={obj.image_after_path}
                        aspectRatio="wider"
                      />
                    </SwiperSlide>
                  ))}
                </Carousel>
                <CompareLabel>{t("before")}</CompareLabel>
                <CompareLabel className="after">{t("after")}</CompareLabel>
              </ImageCompareWrapper>
            </ImageWrapper>
            <div className="stories-pagination" />
          </Col>
        </Row>
        <NavigationsWrapper>
          <SliderBtn className="stories-slider-prev">
            <NextArrow icon="arrow-left" />
            {t("_prev").toLocaleUpperCase()}
          </SliderBtn>
          <Separator />
          <SliderBtn className="stories-slider-next">
            {t("next").toLocaleUpperCase()}
            <NextArrow icon="arrow-right" />
          </SliderBtn>
        </NavigationsWrapper>
      </HybridContainer>
      <LeftLeaf src="/images/real-stories-leaf-left.svg" alt="leaf" />
      <RightLeaf src="/images/real-stories-leaf-right.svg" alt="leaf" />
    </SectionWrapper>
  );
}
