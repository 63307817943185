import Link from "@Components/NEW_TS/common/Link";
import { stripButton } from "@Utils/style-util";
import styled, { css } from "styled-components";

const SLIDER_HEIGHT = 588;
const SLIDER_HEIGHT_TABLET = 500;
const SLIDER_HEIGHT_MOBILE = 350;

export const LandingSliderWrapper = styled.section`
  position: relative;
  margin-bottom: 92px;
  @media (max-width: 767.98px) {
    margin-bottom: 56px;
  }
  .landing-slider-pagination {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    .swiper-pagination-bullet {
      margin-right: 16px;
      min-width: 6px;
      max-width: 6px;
      min-height: 6px;
      max-height: 6px;
      border: 1px solid #fff;
      background-color: #000;
      opacity: 1;
      transition: 0.2s ease background-color;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .swiper-pagination-bullet-active {
      border: 1px solid #000;
      background-color: #fff;
      min-width: 11px;
      max-width: 11px;
      min-height: 11px;
      max-height: 11px;
    }
    @media (min-width: 575.98px) {
      display: none;
    }
  }
`;
// started here

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  position: absolute;
  bottom: 50px;
  left: 7%;
  z-index: 1;
  @media (max-width: 991.98px) {
    position: static;
    margin-top: 20px;
    justify-content: center;
  }
  @media (max-width: 575.98px) {
    display: none;
  }
`;

export const StyledArrow = styled.div<{
  readonly next?: boolean;
}>`
  ${stripButton};
  width: 20px;
  height: 20px;
  z-index: 1;
  background-color: transparent;
  cursor: pointer;
  img {
    display: block;
    object-fit: contain;
  }
  ${({ next }) =>
    next &&
    css`
      transform: ;
      img {
        transform: rotate(-90deg);
      }
    `}

  ${({ next }) =>
    !next &&
    css`
      img {
        transform: rotate(90deg);
      }
    `}
`;

export const HomeShopSlideWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 145px;
  @media (max-width: 1199.98px) {
    padding-right: 160px;
  }
  @media (max-width: 991.98px) {
    flex-direction: column;
    padding-right: 0;
  }
`;

export const TextWrapper = styled.header`
  margin-top: 90px;
  color: #000;
  @media (max-width: 991.98px) {
    margin-top: 45px;
  }
`;
export const TextHeading = styled.h2`
  font-size: 3.4rem;
  font-weight: 500;
  line-height: 66px;
  margin-bottom: 28px;
  @media (max-width: 1100px) {
    font-size: 3rem;
  }
  @media (max-width: 1060px) {
    font-size: 2.5rem;
  }
  @media (max-width: 767.98px) {
    font-size: 2.3rem;
    line-height: 44px;
    margin-bottom: 43px;
  }
`;

export const TextParagraph = styled.p`
  font-size: 1.9rem;
  line-height: 1;
  margin-bottom: 70px;
  @media (max-width: 991.98px) {
    margin-bottom: 35px;
  }
  @media (max-width: 767.98px) {
    font-size: 1.7rem;
    margin-bottom: 45px;
  }
`;

export const TextAction = styled.a`
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 4px /*rtl:1px*/;
  color: #000;
  border-bottom: 4px solid #e4001b;
  padding-bottom: 14px;
  display: inline-block;
  text-transform: uppercase;
  @media (max-width: 991.98px) {
    margin-bottom: 43px;
  }
  @media (max-width: 767.98px) {
    font-size: 1.5rem;
  }
`;

export const SliderImageWrapper = styled.div`
  position: relative;
  border: 1px solid #d8d8d8;
  padding: 10px;
  width: 439px;
  height: 588px;
  background-color: #fff;
  @media (max-width: 1199.98px) {
    width: 370px;
    height: 480px;
  }
  @media (max-width: 991.98px) {
    width: 100%;
    max-width: 334px;
    margin: 0 auto;
    height: 447px;
  }
  /* &:before {
    content: "";
    position: absolute;
    z-index: 2;
    width: 125px;
    height: 125px;
    background-image: url("/icons/leaf1.svg");
    background-size: 124px 118px;
    left: -78px;
    top: -41px;
    -webkit-transform: rotate(20deg) scaleX(-1);
    -ms-transform: rotate(20deg) scaleX(-1);
    transform: rotate(21deg) scaleX(-1);
  } */
`;
export const SliderPointersWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
export const SliderImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  pointer-events: none;
  user-select: none;
`;

export const CurrentSlideCount = styled.p`
  font-size: 2rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 8.08px;
  color: #000;
  margin-right: 18px;
`;

export const ButtonsSeparator = styled.div`
  width: 1.7px;
  height: 20px;
  position: relative;
  background-color: #757575;
  margin: 0 16px 0 12px;
`;
export const InnerWrapper = styled.div`
  img.slider-img {
    height: auto;
  }
`;
export const TopLeaf = styled.img`
  width: 124.2px;
  height: 118.8px;
  position: absolute;
  top: -15px;
  left: 46%;
  transform: scale(-1, 1) rotate(-55deg);
  opacity: 0.5;
  z-index: -1;
  transform: scale(-1, 1) rotate(-55deg) /*rtl: scale(1, 1) rotate(-55deg)*/;
  @media (max-width: 991.98px) {
    display: none;
  }
`;
export const LeftLeaf = styled.img`
  pointer-events: none;
  user-select: none;
  width: 161.7px;
  height: 174.3px;
  position: absolute;
  bottom: 35px;
  left: 0;
  z-index: -1;
  transform: scale(1, 1) /*rtl: scale(-1, 1)*/;
  @media (max-width: 991.98px) {
    display: none;
  }
`;
export const RightLeaf = styled.img`
  pointer-events: none;
  user-select: none;
  width: 247px;
  height: 343px;
  position: absolute;
  bottom: -75px;
  right: 0;
  z-index: -1;
  transform: scale(1, 1) /*rtl: scale(-1, 1)*/;
  @media (max-width: 991.98px) {
    bottom: auto;
    top: 20%;
  }
`;
export const SliderLink = styled(Link)`
  position: relative;
  user-select: none;
`;
