import Head from "next/head";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import SwiperCore, { Autoplay, EffectFade, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { LandingSlide } from "types/landing";
import {
  ButtonsSeparator,
  CurrentSlideCount,
  InnerWrapper,
  LandingSliderWrapper,
  LeftLeaf,
  NavigationWrapper,
  RightLeaf,
  SliderLink,
  StyledArrow,
  TopLeaf
} from "./HomeSlider.styled";

SwiperCore.use([Navigation, EffectFade, Autoplay]);

export default function LandingSlider({ data }: { data: LandingSlide[] }) {
  const [counter, setCounter] = useState(1);

  const isMobile = useMediaQuery({
    maxWidth: 767.98
  });

  return (
    <LandingSliderWrapper>
      <Head>
        {data.map(slide => (
          <link
            rel="preload"
            as="image"
            href={isMobile ? slide.mobile_image_path || slide.image_path : slide.image_path}
          />
        ))}
      </Head>
      <InnerWrapper>
        <Swiper
          navigation={{
            prevEl: ".prev",
            nextEl: ".next"
          }}
          pagination={{
            el: ".landing-slider-pagination",
            type: "bullets",
            clickable: true
          }}
          spaceBetween={0}
          slidesPerView={1}
          autoHeight={true}
          autoplay={{
            ...(data.length > 1 && {
              delay: 4000
            }),
            disableOnInteraction: false
          }}
          loop={data.length > 1}
          onSlideChange={swiper => setCounter(swiper.realIndex + 1)}
        >
          {data.map((slide, i) => (
            <SwiperSlide key={"Home_slide_" + i}>
              <SliderLink
                className="slide-wrapper"
                {...(Boolean(slide.link)
                  ? {
                      href: slide.link
                    }
                  : {
                      as: "div"
                    })}
              >
                <img
                  src={isMobile ? slide.mobile_image_path || slide.image_path : slide.image_path}
                  alt=""
                  className="slider-img"
                  style={{
                    width: `100%`,
                    objectFit: "contain"
                  }}
                />
              </SliderLink>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="landing-slider-pagination" />
        {data.length > 1 && (
          <NavigationWrapper>
            <CurrentSlideCount>0{counter}</CurrentSlideCount>
            <StyledArrow className="prev">
              <img src="/icons/arrow.svg" />
            </StyledArrow>
            <ButtonsSeparator />
            <StyledArrow next className="next">
              <img src="/icons/arrow.svg" />
            </StyledArrow>
          </NavigationWrapper>
        )}
      </InnerWrapper>
      {!isMobile && (
        <>
          <TopLeaf src="/icons/leaf1.svg" alt="TopLeaf" />
          <LeftLeaf src="/icons/left-leaf.svg" alt="LeftLeaf" />
          <RightLeaf src="/icons/right-leaf.svg" alt="RightLeaf" />
        </>
      )}
    </LandingSliderWrapper>
  );
}
