import React from "react";
import { HowStepHeading, HowStepNumber, HowStepText, HowStepWrapper } from "./HomeHowItWorks.styled";

export interface Props {
  stepCount: number;
  stepHeading: string;
  stepText: string;
}

export default function HowStep({ stepCount, stepHeading, stepText }: Props) {
  return (
    <HowStepWrapper>
      <HowStepNumber>{stepCount}</HowStepNumber>
      <HowStepHeading>{stepHeading}</HowStepHeading>
      <HowStepText>{stepText}</HowStepText>
    </HowStepWrapper>
  );
}
